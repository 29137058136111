import React from "react"
import Layout from "../../layouts/default"

import AudioPlayer from "../../components/audio-player"
import Breadcrumb from "../../components/breadcrumb"
import Constrain from "../../components/constrain"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Small from "../../components/small"
import Stack from "../../components/stack"

const Katalog15 = () => {
  return (
    <Layout backdrop="aussen">
      <Seo title="Victor Ullmann: Abendphantasie" />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Victor Ullmann: Abendphantasie",
                link: "/k/15",
              },
            ]}
          />
          <PageTitle>Victor Ullmann: Abendphantasie</PageTitle>
          <Constrain>
            <Stack space={6}>
              <AudioPlayer
                src="katalog/15-victor-ullmann-abendphantasie"
                title="Victor Ullmann: Abendphantasie – Mitsuko Shirai und Hartmut Höll"
              />
              <Small color="whisper">
                Mit freundlicher Genehmigung durch Mitsuko Shirai und Hartmut
                Höll, Capriccio
              </Small>
            </Stack>
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Katalog15
